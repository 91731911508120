import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './VideoCard.css';

const VideoCard = ({ videos }) => {
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);

  const handleVideoPlay = (index) => {
    setActiveVideoIndex(index);
  };

  return (
    <div className="video-card-container">
      <Swiper
        modules={[Navigation, Pagination, Mousewheel]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        mousewheel={true}
        onSlideChange={(swiper) => {
          setActiveVideoIndex(null);
        }}
      >
        {videos.map((videoUrl, index) => (
          <SwiperSlide key={index}>
            <div className="video-card">
              <ReactPlayer
                url={videoUrl}
                width="100%"
                height="100%"
                controls
                playing={activeVideoIndex === index}
                onPlay={() => handleVideoPlay(index)}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export { VideoCard };