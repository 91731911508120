import React, { useState } from 'react';
import { Img, Button, CustomIcons, Input } from 'components';
import './MessageInput.css';

const MessageInput = ({ onSend, isDisabled }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim() && !isDisabled) {
      onSend(message);
      setMessage('');
    }
  };

  return (
    <div className="flex gap-2 mx-auto h-[65px] py-1 px-2 w-full z-10 items-center bg-white-a700 rounded-3xl">
      <Input 
        wrapClassName="w-full"
        className="self-end tracking-[0.15px] !text-[20px] w-full !h-full"
        type="text"
        placeholder="Escribe aquí..."
        name="question"
        id="question"
        value={message}
        onChange={(value) => setMessage(value)}
        onKeyPress={(e) => { if (e.key === 'Enter' && !isDisabled) handleSend(); }}
        autoFocus
        required
      />
      {!isDisabled ?
        <Button 
          className=""
          onClick={handleSend}
          disabled={isDisabled}
        >
          <Img src="images/img_adornmentend.svg" alt="Adornmentend" className="h-[44px] w-[44px]" />
        </Button>
      : <CustomIcons iconName="wired-outline-74-navigation" backgroundColor="bg-white-a700" size={45} />}
    </div>
  );
};

export { MessageInput };