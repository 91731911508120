import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const initialCompanyData = {
  autos: {
    theme: "autos",
    name: "Luxe Auto",
    colors: {
      "background-color": "#111a22",
      "text-color": "white",
      "primary-color": "#1466b8",
      "secondary-color": "#243647"
    },
    products: [
      {
        name: "Car Model X",
        price: "$50,000",
        features: ["Feature 1: Value", "Feature 2: Value", "Feature 3: Value"],
        imageUrl: "https://via.placeholder.com/150"
      },
      {
        name: "Car Model Y",
        price: "$60,000",
        features: ["Feature 1: Value", "Feature 2: Value", "Feature 3: Value"],
        imageUrl: "https://via.placeholder.com/150"
      }
    ],
    videos: [
      { name: "Car Video", url: "https://www.youtube.com/watch?v=xHp0R14_sRY&ab_channel=HyundaiWorldwide" }
    ],
    images: [
      "https://via.placeholder.com/600x400",
      "https://via.placeholder.com/600x400",
      "https://via.placeholder.com/600x400"
    ],
    comparisons: [
      {
        name: "Comparison of Car Models",
        products: [
          {
            name: "Car Model X",
            price: "$50,000",
            features: ["Feature 1: Value", "Feature 2: Value", "Feature 3: Value"],
            imageUrl: "https://via.placeholder.com/150"
          },
          {
            name: "Car Model Y",
            price: "$60,000",
            features: ["Feature 1: Value", "Feature 2: Value", "Feature 3: Value"],
            imageUrl: "https://via.placeholder.com/150"
          }
        ]
      }
    ],
    texts: ["This is an automated response for autos."]
  },
  electrodomesticos: {
    theme: "electrodomesticos",
    name: "Electrodomésticos",
    colors: {
      "background-color": "white",
      "text-color": "#333",
      "primary-color": "#1466b8",
      "secondary-color": "#243647"
    },
    products: [
      {
        name: "Refrigerator Model A",
        price: "$1,200",
        features: ["Capacity: 500L", "Energy Rating: A++", "Noise Level: 39dB"],
        imageUrl: "https://dojiw2m9tvv09.cloudfront.net/36300/product/osbnf2700v6809.jpg"
      },
      {
        name: "Refrigerator Model B",
        price: "$1,100",
        features: ["Capacity: 450L", "Energy Rating: A+", "Noise Level: 42dB"],
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRYF3sgRgfxTj0Dbg3KqJj_DazzzIFyofw7YZc7iZKd0rwvGkee-jQ4w7JmQSXRj3GhQ&usqp=CAU"
      }
    ],
    videos: [
      { name: "Refrigerator Video", url: "https://www.youtube.com/watch?v=zE7bWnD1IKg&ab_channel=Midea" }
    ],
    images: [
      "https://dojiw2m9tvv09.cloudfront.net/36300/product/osbnf2700v6809.jpg",
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRYF3sgRgfxTj0Dbg3KqJj_DazzzIFyofw7YZc7iZKd0rwvGkee-jQ4w7JmQSXRj3GhQ&usqp=CAU",
      "https://cloudfront-us-east-1.images.arcpublishing.com/copesa/HV3MQ3ETCZBKJENCUDYXWR4JQI.jpg"
    ],
    comparisons: [
      {
        name: "Comparison of Refrigerators",
        products: [
          {
            name: "Refrigerator Model A",
            price: "$1,200",
            features: ["Capacity: 500L", "Energy Rating: A++", "Noise Level: 39dB"],
            imageUrl: "https://dojiw2m9tvv09.cloudfront.net/36300/product/osbnf2700v6809.jpg"
          },
          {
            name: "Refrigerator Model B",
            price: "$1,100",
            features: ["Capacity: 450L", "Energy Rating: A+", "Noise Level: 42dB"],
            imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRYF3sgRgfxTj0Dbg3KqJj_DazzzIFyofw7YZc7iZKd0rwvGkee-jQ4w7JmQSXRj3GhQ&usqp=CAU"
          }
        ]
      }
    ],
    texts: ["This is an automated response for electrodomesticos."]
  },
  ropa: {
    theme: "ropa",
    name: "Ropa",
    colors: {
      "background-color": "#121212",
      "text-color": "#ffffff",
      "primary-color": "#bb86fc",
      "secondary-color": "#03dac6"
    },
    products: [
      {
        name: "Dress Model Y",
        price: "$120",
        features: ["Material: Cotton", "Size: M, L, XL", "Color: Red"],
        imageUrl: "https://via.placeholder.com/150"
      },
      {
        name: "Dress Model Z",
        price: "$130",
        features: ["Material: Silk", "Size: S, M, L", "Color: Blue"],
        imageUrl: "https://via.placeholder.com/150"
      }
    ],
    videos: [
      { name: "Dress Video", url: "https://www.youtube.com/watch?v=JKJm4ZaqF3U&ab_channel=AnetteAguirre" }
    ],
    images: [
      "https://via.placeholder.com/600x400",
      "https://via.placeholder.com/600x400",
      "https://via.placeholder.com/600x400"
    ],
    comparisons: [
      {
        name: "Comparison of Dresses",
        products: [
          {
            name: "Dress Model Y",
            price: "$120",
            features: ["Material: Cotton", "Size: M, L, XL", "Color: Red"],
            imageUrl: "https://via.placeholder.com/150"
          },
          {
            name: "Dress Model Z",
            price: "$130",
            features: ["Material: Silk", "Size: S, M, L", "Color: Blue"],
            imageUrl: "https://via.placeholder.com/150"
          }
        ]
      }
    ],
    texts: ["This is an automated response for ropa."]
  }
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('autos');
  const [messages, setMessages] = useState([]);
  const [companyData] = useState(initialCompanyData); 
  useEffect(() => {
    const themeLink = document.getElementById('theme-link');
    if (themeLink) {
      themeLink.href = `/themes/${theme}.css`;
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme, messages, setMessages, companyData }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);