import { validateAll } from 'indicative/validator';

//create class
const messages = {
  'required': '{{ field }} es obligatorio',
  'date': 'El campo {{ field }} la fecha tiene mal formato',
  'max': 'El campo {{ field }} excede el máximo',
  'number': 'El campo {{ field }} solo acepta de números',
  'array': 'El campo {{ field }} debe ser tipo Array',
  'email': 'Correo Electrónico debe ser un email valido',
  'email.required': 'Correo Electrónico es obligatorio',
  'run.required': 'No se puede identificar el run a consultar',
  'dv.required': 'No se puede identificar el run a consultar',
  'id_usuario.required': 'Usuario no autorizado',
  'email.regex': 'Correo Electrónico debe ser un correo electronico valido',
  'min': 'El campo {{ field }} debe tener al menos {{ argument.0 }} caracter/es',
  'year.required': 'El campo Año académico es obligatorio',
};

const rulesInternal = async(inputBody = {}, rules = {}) => {
  await validateAll(inputBody, rules, messages);
}

const sanitizeTextInternal = inputText => {
  let output = inputText;
  if (output !== null) {
      try {
          const conversion = [
              { "in": /  /g, "out": ` ` },
              { "in": /"/g, "out": `'` },
              { "in": /\134/g, "out": `/` },
              { "in": /\n/g, "out": `n` },
              { "in": /\N/g, "out": `N` }
          ];

          for (let item in conversion) {
              output = output.replace(conversion[item].in, conversion[item].out)
          }
      } catch (error) {
          console.log("[ERROR] sanitizeTextInternal ", error)
      }
  }
  return output;
}

export default {
  rules: rulesInternal,
  sanitizetext: sanitizeTextInternal
}