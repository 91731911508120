import React from 'react';
import './Modal.css';

const ModalRotate = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-rotate-overlay">
      <div className="modal-rotate-content">
        <div className="modal-rotate-phone mx-auto mt-2"></div>
        <div className="modal-rotate-message mx-auto">{children}</div>
      </div>
    </div>
  );
};

export { ModalRotate };