import React from 'react';
import './LoadingIndicator.css';

const LoadingIndicator = () => (
  <div className="loading-indicator">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
);

export { LoadingIndicator };