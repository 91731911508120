import { useEffect, useRef, useState, useCallback } from 'react';
const useWebSocket = () => {
  const ws = useRef(null);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isCreatedWS, setIsCreatedWS] = useState(false);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const fetchSessionId = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/new-session`);
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      sessionStorage.setItem('sessionId', data.session_id);
      setSessionId(data.session_id);
    } catch (error) {
      setError(error.message);
      console.error('Failed to create new session:', error);
    }
  }, []);
  useEffect(() => {
    const storedSessionId = sessionStorage.getItem('sessionId');
    if (storedSessionId) {
      setSessionId(storedSessionId);
    } else {
      fetchSessionId();
    }
  }, [fetchSessionId]);
  useEffect(() => {
    if (sessionId && !ws.current) {
      ws.current = new WebSocket(`${process.env.REACT_APP_CHAT_BASE_WS}/ws/chat/${sessionId}`);
      ws.current.onopen = () => {
        console.log(`WebSocket connection opened for session: ${sessionId}`);
        setIsCreatedWS(true);
      };
      ws.current.onclose = (event) => {
        sessionStorage.removeItem('sessionId')
        setSessionId(null)
        console.log(`WebSocket connection closed for session: ${sessionId}`, event);
        ws.current = null;
      };
      ws.current.onerror = (error) => {
        sessionStorage.removeItem('sessionId')
        setSessionId(null)
        console.error(`WebSocket error for session: ${sessionId}`, error);
      };
      ws.current.onmessage = (event) => {
        const data = event.data;
        setMessageQueue(prev => [...prev, data]);
        setIsTyping(true);
      };
    }
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [sessionId]);
  const handleSendMessage = useCallback((message) => {
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        ws.current.send(message.text);
      }
  }, []);
  return { handleSendMessage, messageQueue, isTyping, setIsTyping, setMessageQueue, isCreatedWS, setIsCreatedWS };
};
export default useWebSocket;