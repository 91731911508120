import React from "react";

const sizes = {
  textxs: "text-sm font-medium not-italic",
  headingxs: "text-base font-bold",
  headings: "text-xl font-bold",
  headingmd: "text-2xl font-bold md:text-[22px]",
  headinglg: "text-[38px] font-bold md:text-4xl sm:text-[34px]",
};

const Heading = ({ children, className = "", size = "textxs", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-black-900_99 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
