import { Text, RatingBar, Heading, Img } from "../../components";
import React from "react";

export default function PageRow() {
  return (
    <div className="flex flex-col items-center justify-center gap-8 px-14 py-[260px] md:p-5">
      <div className="flex w-[18%] flex-col items-center gap-4 p-4 md:w-full">
        <Img src="images/img_avatar.png" alt="Avatar" className="h-[80px] w-[80px] rounded-[40px] object-cover" />
        <div className="flex w-[88%] flex-col items-center justify-center md:w-full">
          <Text as="p" className="tracking-[0.15px]">
            Haz sido atentido por
          </Text>
          <Heading as="h1" className="tracking-[0.25px]">
            Juan Carlos
          </Heading>
          <Img src="images/img_settings.svg" alt="Settings" className="mx-[38px] h-[22px] w-full md:mx-0 md:h-auto" />
        </div>
      </div>
      <div className="mb-6 flex w-[38%] flex-col gap-6 md:w-full">
        <Heading as="h2" className="text-center leading-7 tracking-[0.25px]">
          Muchas gracias por contactarte con nosotros, esperamos que el proceso haya sido de tu agrado.
        </Heading>
        <div className="flex flex-col items-center justify-center gap-2.5 px-14 md:px-5">
          <RatingBar value={1} isEditable={true} size={24} className="flex gap-2.5" />
          <Text as="p" className="tracking-[0.15px]">
            Califica tu experiencia
          </Text>
        </div>
      </div>
    </div>
  );
}
