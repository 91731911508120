import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const MyImageGallery = ({ images, onImageClick, width = '40vw', height = '40vh' }) => {
  console.log('images  ', images);

  return (
    <div style={{ paddingBottom: '2%', paddingTop: '2%' }}>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        cursor={'pointer'}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} style={{ width, height }}>
            <img 
              src={image} 
              alt={`Slide ${index}`} 
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }} 
              onClick={() => onImageClick(image)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export { MyImageGallery };