import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './TextMessage.css';

const TextMessage = ({ sender, message, time, avatar }) => (
  <div className={`flex flex-row gap-2 p-1 ${sender.toLowerCase()}`}>
    {(sender === process.env.REACT_APP_AGENT_NAME) ? <div className="items-start sm:hidden md:hidden"> {avatar} </div>: null}
    <div className="messageContent">
      <div className="messageHeader">
        {(sender === process.env.REACT_APP_AGENT_NAME) ? <span className="sender">{sender}</span> : ((time) && <span className="time">{time}</span>)}
        {(sender === process.env.REACT_APP_AGENT_NAME) ? ((time) && <span className="time">{time}</span>) : <span className="sender">{sender}</span>}
      </div>
      <div className={`messageText ${(sender === process.env.REACT_APP_AGENT_NAME) ? "text-left" : "text-justify"}`}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{message}</ReactMarkdown>
      </div>
    </div>
    {(sender === process.env.REACT_APP_AGENT_NAME) ? null : <div className="items-start"> {avatar} </div>}
  </div>
);

export { TextMessage };