import React from "react";
import { Player } from '@lordicon/react';

export class CustomIcons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
    };
    this.playerRef = React.createRef();
  }

  loadIcon(iconName) {
    import(`./images/${iconName}.json`)
      .then((iconModule) => {
        const icon = JSON.parse(JSON.stringify(iconModule.default));
        this.setState({ icon });
      })
      .catch((error) => console.error(`Error loading icon: ${iconName}`, error));
  }

  componentDidMount() {
    const { iconName } = this.props;
    this.loadIcon(iconName);
  }

  render() {
    const { icon } = this.state;
    const { backgroundColor = "", size = 100 } = this.props;
    return icon ? (
      <div className={`${backgroundColor} rounded-full`}>
        <Player
          ref={this.playerRef}
          size={size}
          icon={icon}
        />
      </div>
    ) : null;
  }
}